body {
    color: #fff;
    background: #4a0c7e;
    border-top: 5px solid #ffed81;
}

input, select, textarea {
    -moz-box-shadow: inset 0 1px 0 0 #c3c3c3;
    -webkit-box-shadow: inset 0 1px 0 0 #c3c3c3;
    box-shadow: inset 0 1px 0 0 #c3c3c3;
    background-color: #fff;
    border-top: 1px solid #7c7c7c;
    border-left: 1px solid #c3c3c3;
    border-right: 1px solid #c3c3c3;
    border-bottom: 1px solid #ddd;
    color: #333;
}

input:hover, select:hover, textarea:hover {
    border: 1px solid #000;
}

input:active, select:active, textarea:active, input:focus, select:focus, textarea:focus {
    -moz-box-shadow: 0 0 19px -6px #dd9231;
    -webkit-box-shadow: 0 0 19px -6px #dd9231;
    box-shadow: 0 0 19px -6px #dd9231;
    border: 1px solid #dd9231;
}

input[type=submit], #paiementchoix .button {
    background: #ffe441;
    color: #333;
}

input[type=submit]:hover, #paiementchoix .button:hover {
    background-color: #b3a235;
    text-shadow: 0 -1px 0 #886333;
    -moz-box-shadow: 0 1px 0 0 #000;
    -webkit-box-shadow: 0 1px 0 0 #000;
    box-shadow: 0 1px 0 0 #000;
    color: #fff;
}

#left_bandeau {
    background: #444;
}

nav#nav-principal {
    border-bottom: 1px solid #333;
    border-top: 1px solid #333;
}

.bx-next {
    border-left: 1px solid #84042d;
    -moz-box-shadow: inset 0 1px 0 0 #d77191;
    -webkit-box-shadow: inset 0 1px 0 0 #d77191;
    box-shadow: inset 0 1px 0 0 #d77191;
    background: 0;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c63462', endColorstr='#900634');
    background-color: #c63462;
}

ul#menu li a {
    color: #fff;
    border-top: 1px solid #410375;
    border-bottom: 1px solid #000;
}

ul#menu li a:hover {
    color: #fff;
    text-shadow: 1px 1px 0 #4a0c7e;
    background: #ffed81 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OThDNkE0Mzg1REZCMTFFMTkyRUNCRTU4N0ZEMTVBNEIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OThDNkE0Mzk1REZCMTFFMTkyRUNCRTU4N0ZEMTVBNEIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5OEM2QTQzNjVERkIxMUUxOTJFQ0JFNTg3RkQxNUE0QiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5OEM2QTQzNzVERkIxMUUxOTJFQ0JFNTg3RkQxNUE0QiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PinmruMAAABoSURBVHjaYvr//z8DDqwExGf+o4K7UHFcehgY8EkCsSCphhIykGRDiTGQJEOJNZBoQ5EjgFyAYigTA+VACYhXwTjUMPAeEIehGwgSZCSAhYD4LBbDXKE0BAxELFM1HVI1p5CVlwECDADyOZZrr/2D0wAAAABJRU5ErkJggg==) no-repeat 5px center;
}

.contentright ul a {
    color: #ffed81;
    background: #1d0134;
}

#contentpage .produits p.nomprod, #contentpage .produits p.titreprod {
    text-shadow: 1px 1px 0 #4a0c7e;
}

#contentpage .produits HR {
    border-top: 1px dotted #d48da4;
}

#contentpage .produits a.addtobasket, #contentpage .produits a.addbasket, #content .button, #fiche-produit a.button {
    background: #ffe441;
    color: #333;
    text-shadow: 1px 1px 0 #ffed81;
}

#contentpage .produits a.addtobasket:hover, #contentpage .produits a.addbasket:hover, #contentpage .produits a.addbasket.actif, .button:hover {
    background-color: #b3a235;
    color: #fff;
    text-shadow: 0 -1px 0 #886333;
}

#contentpage .old_price {
    background: #FFF;
}

#home_products_wrapper .produits p.nomprod {
    color: #fff;
    text-shadow: 0 2px 0 #000;
}

#home_products_wrapper .produits p.prixprod .prixprod-bg {
    background: #222;
    color: #fff;
}

#home_products_wrapper .produits a.addtobasket {
    -moz-box-shadow: 0 1px 0 0 #c7c7c7;
    -webkit-box-shadow: 0 1px 0 0 #c7c7c7;
    box-shadow: 0 1px 0 0 #c7c7c7;
    background: linear-gradient(to bottom, #f7f9fb0, #ffffff48, #ebebeb54, #d9d8da100);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f9fb', endColorstr='#d9d8da', GradientType=0);
    border: 1px solid #a8adaf;
    color: #333;
    text-shadow: 1px 1px 0 #fff;
}

#home_products_wrapper .produits a.addtobasket:hover {
    -moz-box-shadow: inset 0 1px 0 0 #d77191;
    -webkit-box-shadow: inset 0 1px 0 0 #d77191;
    box-shadow: inset 0 1px 0 0 #d77191;
    background: 0;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c63462', endColorstr='#900634');
    background-color: #c63462;
    border: 1px solid #85042f;
    color: #fff;
    text-shadow: 0 -1px 0 #4f1125;
}

.message_lo {
    -moz-box-shadow: 0 4px 0 0 #272727;
    -webkit-box-shadow: 0 4px 0 0 #272727;
    box-shadow: 0 4px 0 0 #272727;
    background: #410375;
    border: 1px solid #410375;
}

.message_lo h4 em,
.message_lo .h4 em {
    color: #999;
}

.message_lo .note {
    background: #3d016f;
    color: #FFF;
}

#footer {
    background: #1d0134;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: #1d0134;
    border-color: #1d0134;
}

.pagination > li > a, .pagination > li > span {
    color: #1d0134;
}

.pagination.pull-right li.active a {
    color: #fff !important;
}

#footer ul a {
    color: #ffed81;
    background: #4a0c7e;
}

.footer a {
    color: #7a3cae;
}

#nav-principal {
    .navigation_link_p {
        color: #fff;
    }
    .navigation_button_p {

        &:hover, &:focus, &.actif {
            > .navigation_link_p {
                color: #ffed81;
            }
        }
    }
    .sous_navigation_link_p {
        color: #fff;
    }
    .sous_navigation_button_p {
        background: #1d0134;
        &:hover, &:focus, &.actif {
            background: #1d0134;
            > .sous_navigation_link_p {
                color: #ffed81;
            }
        }
    }
}

nav#nav-principal > ul li:hover ul {

    border: 1px solid #444;
}

.filter a:hover, .current a {
    color: #fff !important;
}

a.addmsglo {
    background: #410375;
    color: #FFF !important;
    border: 1px solid #410375;
}

a.addmsglo:hover {
    background: #000;
    color: #FFF;
    border: 1px solid #410375;
}

input[type=text], input[type=select], textarea, select {
    border: 1px solid #ccc;
}

input[type=text]:hover, input[type=select]:hover, textarea:hover, select:hover {
    border: 1px solid #5A5A5A;
}

#boutique_class .produits {
    border: 1px solid #410375;
}

#boutique_class .addbasket:hover {
    color: #bbb;
}

::selection, ::-moz-selection {
    background: #452700;
}

h2, .h2, a, .linkcoms, p.diaporama, p.diaporama a, #contentpage .photos a.addcommentaires:hover, .nomprod a {
    color: #ffed81;
}

#wrapper, #bg_slider {
    background: #4a0c7e;
}

.slogan, #contentpage a:hover, #contentpage .produits {
    color: #fff;
}

#left_bandeau a:hover, #home_products_wrapper .produits p.nomprod a {
    color: #ccc;
}

nav#nav-principal span.home a, .bx-prev {
    border-right: 1px solid #84042d;
    -moz-box-shadow: inset 0 1px 0 0 #d77191;
    -webkit-box-shadow: inset 0 1px 0 0 #d77191;
    box-shadow: inset 0 1px 0 0 #d77191;
    background: 0;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c63462', endColorstr='#900634');
    background-color: #c63462;
}

nav#nav-principal span.home a:hover, .bx-prev:hover, .bx-next:hover {
    background: #82042d;
}

ul#menu li:last-child a, .texthautcontact {
    border-bottom: 1px solid #410375;
}

.contentright ul a:hover, #footer ul a:hover {
    color: #0b0b0b;
    background: #ffed81;
}

#contentpage .remise, #home_products_wrapper .remise,.remise {
    background: 0;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d6ff5b', endColorstr='#abec08');
    background-color: #d6ff5b !important;
    color: #333 !important;
}

#home_products_wrapper .produits p.nomprod a:hover, .message_lo p.web a {
    color: #eee;
}

#home_products_wrapper .produits HR, .divider {
    border-top: 1px solid #CCC;
}

dl, .filter a {
    color: #777;
}

.produit_etiquette:after {
    border-color: rgb(172, 115, 221) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);

}

.produit_etiquette {
    background: rgb(172, 115, 221);
}

.produit_etiquette2 {
    background: rgb(172, 115, 221);
}

.produit_etiquette2:after {
    border-color: rgba(0, 0, 0, 0) rgb(121, 77, 160) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

@media screen and (max-width: 768px) {
    nav#nav-principal > ul li ul {
        background: #1d0134;
        border: 1px solid #444;
    }
}

/** Flux Panier **/

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}